"use strict";

/* Gathering all quantity buttons */
const plusButtons = document.querySelectorAll(".button-plus");
const minusButtons = document.querySelectorAll(".button-minus");

/* Setting event listener for all plus buttons */
plusButtons.forEach( ( plusButton ) => {
  plusButton.addEventListener("click", () => {
    const quantityDiv = plusButton.closest(".quantity_wrapper");
    const inputNumber = quantityDiv.querySelector(".qty");
    const inputValue = inputNumber.getAttribute("value");
    let newValue = parseInt(inputValue, 10) + 1;
    inputNumber.setAttribute("value", newValue);
  })
})

/* Setting event listener for all minus buttons */
minusButtons.forEach( ( minusButton ) => {
  minusButton.addEventListener("click", () => {
    const quantityDiv = minusButton.closest(".quantity_wrapper");
    const inputNumber = quantityDiv.querySelector(".qty");
    const inputValue = inputNumber.getAttribute("value");

    if (inputValue > 1 ) {
      let newValue = parseInt(inputValue, 10) - 1;
      inputNumber.setAttribute("value", newValue);
    }

  })
})

/* Setting observer for input value change and trigger cart update */
const cartPage = document.body.classList.contains("woocommerce-cart");
const allInputNumbers = document.querySelectorAll(".qty");
const cartUpdate = document.querySelector("button[name='update_cart']");

allInputNumbers.forEach( ( inputNumber ) => {

  const observer = new MutationObserver( ( mutationsList) => {
    for( const mutation of mutationsList ) {
      if (cartPage && mutation.attributeName === "value") {
        cartUpdate.removeAttribute("disabled");
        cartUpdate.click();
      }
    }
  });

  observer.observe(inputNumber, { attributes: true });

})






/*jQuery(function ($) {
  if (!String.prototype.getDecimals) {
    String.prototype.getDecimals = function () {
      var num = this,
        match = ("" + num).match(/(?:\.(\d+))?(?:[eE]([+-]?\d+))?$/);
      if (!match) {
        return 0;
      }
      return Math.max(
        0,
        (match[1] ? match[1].length : 0) - (match[2] ? +match[2] : 0)
      );
    };
  }
  // Quantity "plus" and "minus" buttons
  $(document.body).on("click", ".button-plus, .button-minus", function () {
    var $qty = $(this).closest(".quantity").find(".qty"),
      currentVal = parseFloat($qty.val()),
      max = parseFloat($qty.attr("max")),
      min = parseFloat($qty.attr("min")),
      step = $qty.attr("step");

    // Format values
    if (!currentVal || currentVal === "" || currentVal === "NaN")
      currentVal = 0;
    if (max === "" || max === "NaN") max = "";
    if (min === "" || min === "NaN") min = 0;
    if (
      step === "any" ||
      step === "" ||
      step === undefined ||
      parseFloat(step) === "NaN"
    )
      step = 1;

    // Change the value
    if ($(this).is(".button-plus")) {
      if (max && currentVal >= max) {
        $qty.val(max);
      } else {
        $qty.val((currentVal + parseFloat(step)).toFixed(step.getDecimals()));
      }
    } else {
      if (min && currentVal <= min) {
        $qty.val(min);
      } else if (currentVal > 0) {
        $qty.val((currentVal - parseFloat(step)).toFixed(step.getDecimals()));
      }
    }

    function quantity_upd() {
      $(".woocommerce-cart-form").on("change", ".qty", function () {
        $("[name='update_cart']").removeAttr("disabled");
        $("[name='update_cart']").trigger("click");
      });
    }

    // Trigger change event
    $qty.trigger("change", quantity_upd());
  });
});*/
