"use strict";

import { gsap } from "gsap";
const tl = gsap.timeline();

//Variables
const hamburgerMenu = document.querySelector("#hamburger-menu");
const hamburgerWrapper = document.querySelector(".hamburger_wrapper");
const header = document.querySelector("#header");
const navMenu = document.querySelector("nav.main_menu");
const menuItems = document.querySelectorAll(
  "ul.main_menu_wrapper .menu-item a"
);
const logo = document.querySelector(".site_logo");

//Functions
const makeNavVisible = () => {
  navMenu.classList.add("open");
};

const makeNavHidden = () => {
  navMenu.classList.remove("open");
};

const openMenuAnimation = () => {
  tl.fromTo(
    header,
    { height: "auto" },
    { duration: 1, height: "100vh", onComplete: makeNavVisible }
  ).fromTo(
    menuItems,
    { y: -20, autoAlpha: 0 },
    { duration: 0.7, stagger: { each: 0.1 }, y: 0, autoAlpha: 1 },
    "-=0.2"
  );
};

const closeMenuAnimation = () => {
  tl.fromTo(
    menuItems,
    { y: 0, autoAlpha: 1 },
    {
      duration: 0.35,
      stagger: { each: 0.05 },
      y: -20,
      autoAlpha: 0,
      onComplete: makeNavHidden,
    }
  )
    .fromTo(
      header,
      { height: "100vh" },
      {
        duration: 0.5,
        height: "auto",
        onComplete: () => {
          document.body.classList.remove("stop-scrolling"); //enable scrolling
          hamburgerWrapper.classList.remove("open"); //animate hamburger menu
        },
      }
    )
    .to(menuItems, { duration: 0.1, autoAlpha: 1, y: 0 }); //to reset
};

//Click event for menu items
menuItems.forEach((item) => {
  item.addEventListener("click", (event) => {
    //trigger only when hamburger menu is open
    if (hamburgerWrapper.classList.contains("open")) {
      event.preventDefault();
      closeMenuAnimation();
      //wait for menu to close and then change href
      if (!event.target.href) {
        setTimeout(() => {
          window.location = event.target.parentElement.href;
        }, 1500);
      } else {
        setTimeout(() => {
          window.location = event.target.href;
        }, 1500);
      }
    }
  });
});

//Click event for logo click
logo.addEventListener("click", (event) => {
  //trigger only when hamburger menu is open otherwise act normally
  if (hamburgerWrapper.classList.contains("open")) {
    event.preventDefault();
    closeMenuAnimation();
    //wait for menu to close and then change href
    setTimeout(() => {
      window.location = event.target.href;
    }, 1500);
  }
});

//Click event for hamburger menu
hamburgerMenu.addEventListener("click", () => {
  if (hamburgerWrapper.classList.contains("open")) {
    closeMenuAnimation();
  } else {
    hamburgerWrapper.classList.add("open"); //animate hamburger menu
    document.body.classList.add("stop-scrolling"); //disable scrolling
    openMenuAnimation();
  }
});

//reset menu if xl screen
window.addEventListener("resize", () => {
  if (window.innerWidth >= 1280 && hamburgerWrapper.classList.contains("open"))
    closeMenuAnimation();
});
