import "./handlers/hamburger-menu";
import HomeSwiper from "./handlers/swiper-home";
import "./handlers/woo-quantity-panel";

//Home Page slider
HomeSwiper();

//Home Page newsletter form success message trigger
const homeRowForm = document.querySelector(".ml-subscribe-form-5236907 .row-form");
const homeRowSuccess = document.querySelector(".ml-subscribe-form-5236907 .row-success");

// Hide success div
if (homeRowSuccess) {
  homeRowSuccess.style.display = "none";
}

window.ml_webform_success_5236907 = () => {
  homeRowSuccess.style.display = "block";
  homeRowForm.style.display = "none";
}

//Single post newsletter form success message trigger
const postRowForm = document.querySelector(".ml-subscribe-form-5240342 .row-form");
const postRowSuccess = document.querySelector(".ml-subscribe-form-5240342 .row-success");

// Hide success div
if (postRowSuccess) {
  postRowSuccess.style.display = "none";
}

window.ml_webform_success_5240342 = () => {
  postRowSuccess.style.display = "block";
  postRowForm.style.display = "none";
}

//Select function for mobile blog category change
const selectBlogCategory = document.querySelector("#cat");
if (selectBlogCategory)
  selectBlogCategory.addEventListener("change", (event) => {
    if (event.target.value === "0") {
      window.location = "/blog/";
    } else {
      window.location = "/?cat=" + event.target.value;
    }
  });

//Select function for mobile shop category change
const selectShopCategory = document.querySelector("#product_cat");
if (selectShopCategory)
  selectShopCategory.addEventListener("change", (event) => {
    if (event.target.value === "0") {
      window.location = "/sklep/";
    } else {
      window.location = "/?product_cat=" + event.target.value;
    }
  });

// Styling class for Blog category all
if (document.body.classList.contains("blog")) {
  document.querySelector(".cat-item-all").classList.add("current-cat");
} else if (document.body.classList.contains("blog category")) {
  document.querySelector(".cat-item-all").classList.remove("current-cat");
}

// Styling class for WooCommerce shop category all
if (
  document.body.classList.contains("archive") &&
  document.body.classList.contains("woocommerce-shop")
) {
  document
    .querySelector(".product-categories-list .cat-item-all")
    .classList.add("current-cat");
} else if (
  document.body.classList.contains("archive") &&
  document.body.classList.contains("woocommerce")
) {
  document
    .querySelector(".product-categories-list .cat-item-all")
    .classList.remove("current-cat");
}

// Adding active class to cart and checkout order progress fields
const cartPage = document.body.classList.contains("woocommerce-cart");
const checkoutPage = document.body.classList.contains("woocommerce-checkout");
const cartSteps = document.querySelectorAll("li.order-step.cart");
const checkoutSteps = document.querySelectorAll("li.order-step.checkout");

cartSteps.forEach((cartStep) => {
  if (cartPage) cartStep.classList.add("active");
});

checkoutSteps.forEach((checkoutStep) => {
  if (checkoutPage) checkoutStep.classList.add("active");
});

//Select function for mobile myaccount menu change
const selectMyAccount = document.querySelector("#myaccount_navigation");
if (selectMyAccount)
  selectMyAccount.addEventListener("change", (event) => {
    window.location = event.target.value;
  });

//Adding selected attribute
const selectMyAccountOptions = document.querySelectorAll(
  "#myaccount_navigation option"
);
selectMyAccountOptions.forEach((selectMyAccountOption) => {
  if (selectMyAccountOption.classList.contains("is-active"))
    selectMyAccountOption.selected = "selected";
});

//Turning autocomplete attribute at myaccount account details for new password
const newPassword = document.querySelector("#password_1");
const confirmNewPassword = document.querySelector("#password_2");

if (newPassword && confirmNewPassword) {
  newPassword.autocomplete = "new-password";
  confirmNewPassword.autocomplete = "new-password";
}

//Remove duplicated div with woocommerce notices
if (document.body.classList.contains("woocommerce-checkout")) {
  const checkoutNotifationWrappers = document.querySelectorAll(
    ".woocommerce-notices-wrapper"
  );
  // Keep first wrapper and remove rest
  checkoutNotifationWrappers.forEach((wrapper, i) => {
    if (!(i === 0)) wrapper.remove();
  });
}
