"use strict";

import Swiper, {

  Autoplay,
  Navigation,
  Pagination,
  Keyboard
} from 'swiper';

import { gsap } from "gsap";

const tl = gsap.timeline();
const autoplay = 10000; //time in ms

const progressBarAnimation = () => {
  const aPlay = autoplay / 1000; //time in s
  tl.clear();
  tl.fromTo(
    ".swiper_slide_header",
    { y: -50, opacity: 0.1, ease: "power3.out" },
    { duration: 1, delay: 0.1, opacity: 1, y: 0 }
  )
    .fromTo(
      ".swiper_slide_description",
      { y: 50, opacity: 0.1, ease: "power3.out" },
      { duration: 1, delay: 0.1, opacity: 1, y: 0 },
      "=-1"
    )
    .fromTo(
      ".progress_bar",
      { width: "0%" },
      { duration: aPlay, width: "100%" },
      "=-1"
    );
};

export default function HomeSwiper() {

  const homeSwiperContainer = document.querySelector(".homeSwiper")

  const swiper = new Swiper(homeSwiperContainer, {
    modules: [Autoplay, Navigation, Pagination, Keyboard],
    loop: true,
    autoplay: {
      delay: autoplay,
      disableOnInteraction: false,
    },
    keyboard: {
      enabled: true,
    },
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    on: {
      init: progressBarAnimation,
      slideChange: progressBarAnimation,
    },
  });
}
